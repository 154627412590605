import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";

const Services = (props) => {
  const services = props.data.allMarkdownRemark.edges;
  const path = props.uri;

  return (
    <Layout bodyClass="page-services" path={path}>
      <SEO 
        title="Services" 
        keywords={
          [
            'Central East', 
            'Central East Corporation', 
            'Central East Corporation LTD', 
            'Consulting Services', 
            'Product Inspection', 
            'Logistics Solutions', 
            'Project Managment', 
            'Product Development',
            'Quality Services',
            'Sample Consolidation'
          ]
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Central East Corporation Ltd is a Hong Kong based sourcing organization and a trading company with over 3 years of experience in purchasing and sourcing in Asia."
        />
      </Helmet>
      
      <div className="container pb-6">
        <div className="row justify-content-evenly">
          <div className="col-12 mt-7">
            <h2 className="text-center title-1 mb-3 mt-3">Our Services</h2>
          </div>
          {services.map((edge) => (
            <div
              key={edge.node.frontmatter.path}
              className={`col-12 ${
                edge.node.frontmatter.no === 2 || edge.node.frontmatter.no === 3
                  ? "col-md-8"
                  : "col-md-4"
              } mb-1`}
              id={`${edge.node.frontmatter.path.split("#")[1]}`}
            >
              <div className="card-two justify-content-start">
                <div className="card-image">
                  <img
                    src={edge.node.frontmatter.imageLight}
                    alt={`service ${edge.node.title}`}
                  />
                </div>
                <h2 className="card-title">{edge.node.frontmatter.title}</h2>
                <div className="card-content">
                  <div
                    dangerouslySetInnerHTML={{ __html: edge.node.html }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___no], order: ASC }
    ) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            no
            title
            imageLight
            path
          }
        }
      }
    }
  }
`;

export default Services;
